.btn-secondary, .btn-secondary:hover, .btn-secondary:focus {
  color: #333;
  text-shadow: none;
}

body {
  text-shadow: 0 .05rem .1rem #00000080;
  box-shadow: inset 0 0 5rem #00000080;
}

.cover-container {
  max-width: 42em;
}

.nav-masthead .nav-link {
  color: #ffffff80;
  border-bottom: .25rem solid #0000;
}

.nav-masthead .nav-link:hover, .nav-masthead .nav-link:focus {
  border-bottom-color: #ffffff40;
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

/*# sourceMappingURL=index.fc701f66.css.map */
